'use client'

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ModalContext } from 'src/components/providers/ModalProvider'
import { EnModal } from 'src/enums'
import { sleep } from 'src/helpers/main.helper'
import { t } from 'src/helpers/translate.helper'
import AdultModal from './AdultModal'
import AuthModal from './AuthModal'
import CommentComplainModal from './CommentComplainModal'
import ComplainModal from './ComplainModal'
import EmailModal from './EmailModal'
import SubscriptionModal from './SubscriptionModal'

const Modal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { activeModal, toggleModal, dataModal } = useContext(ModalContext)

  useEffect(() => {
    setIsOpen(activeModal ? true : false)
  }, [activeModal])

  const onClose = async () => {
    setIsOpen(false)
    await sleep(300)

    toggleModal(null)
  }

  const getTitle = () => {
    switch (activeModal) {
      case EnModal.COMMENT_COMPLAIN:
      case EnModal.COMPLAIN: {
        return 'Send Complain'
      }

      case EnModal.ADULT: {
        return 'Content only for adult'
      }

      case EnModal.EMAIL: {
        return 'Update profile'
      }

      default: {
        return null
      }
    }
  }

  const title = getTitle()

  const renderContent = () => {
    switch (activeModal) {
      case EnModal.AUTH: {
        return <AuthModal />
      }

      case EnModal.ADULT: {
        return <AdultModal onClose={onClose} />
      }

      case EnModal.COMPLAIN: {
        return <ComplainModal onClose={onClose} />
      }

      case EnModal.EMAIL: {
        return <EmailModal />
      }

      case EnModal.COMMENT_COMPLAIN: {
        return (
          <CommentComplainModal
            commentId={dataModal?.commentId || 0}
            onClose={onClose}
          />
        )
      }

      case EnModal.SUBSCRIPTION: {
        return <SubscriptionModal onClose={onClose} />
      }

      default: {
        return null
      }
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-1000/50" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="w-full max-w-md modal overflow-hidden z-[100] transition-all">
                <div className="grid justify-between relative items-center grid-flow-col px-5 py-3.5">
                  <DialogTitle
                    as="h3"
                    className="font-medium text-lg leading-4"
                  >
                    {title ? t(title) : null}
                  </DialogTitle>

                  <button
                    onClick={onClose}
                    className="p-1.5 absolute transform top-[9px] hover:rotate-180 right-[12px] rounded-full hover hover:text-primary hover:dark:bg-black-650 hover:bg-gray-200/50"
                  >
                    <XMarkIcon className="w-4" />
                  </button>
                </div>

                <div className="px-5 pb-3.5">{renderContent()}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
